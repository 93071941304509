// ------------------------- \\
// --- Classes des views --- \\
// ------------------------- \\

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve les différentes fonctions utilisées par les views dans transition.js. Ces fonction
// vont servir à appeler les scripts spécifiques ou les plugins utilisés dans les page web. Les fonctions appelées
// sont réparties dans différents fichiers (slicks, overlay), afin de garder le tout le plus structuré possible.
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import svg4everybody from 'svg4everybody/dist/svg4everybody.legacy';
import {
  PARALLAX, SCROLLFIRE, OBSERVER,
} from './main';
import {
  removeHoverOnMobile, fixObjectFitIE, hundredVH,
} from './functions/helper';
import {
  consoleMessage, goToBackend, fixedArrow, imageStrip, cssVarsIE, dragAndSlide, drawers, projectsFilters, clickToScrollToBlock,
} from './functions/functions';
import { overlayMenu } from './functions/overlay';
import {
  slickCitation, slickProject, slickThreeImages, destroySlick, slickEtude,
} from './functions/slicks';
import backToTopAndLinks from './functions/back-to-top-and-links';
//-----------------------------------------------------------------------------------------------------------------

window.openOverlayByscuit = openOverlayByscuit

export function openOverlayByscuit() {
  cc.showSettings(0);
}

/*
  Vérifie si un élément existe à chaque x ms pendant x ms
  - Selector: Classe ou id de l'élément
  - Callback: Fonction à exécuter si l'élément est trouvé
  - CheckFrequencyInMs: Fréquence des checks à chaque x millisecondes
  - timeoutInMs: Temps en millisecondes après lequel la fonction arrêtera de chercher l'élément même s'il n'est pas trouvé
*/
function waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
  var startTimeInMs = Date.now();
  (function loopSearch() {
    if (document.querySelector(selector)) {
      callback(); // Callback qui exécute le code dans la variable
      return;
    } else {
      setTimeout(function () {
        if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs) {
          return;
        }
        loopSearch(); // Loop récursif
      }, checkFrequencyInMs);
    }
  })();
}

// Clear les cookies des catégories désactivés (Domaine interne seulement)
function updateCookies(reload = true) {
  let cookies = document.cookie.split('; ');

  if (cookies.some(str => str.includes('cc_cookie_byscuit'))) {
    const cookieCategories = cc.getAllBlocks(); // Toutes les catégories
    const acceptedCats = cc.getUserPreferences().accepted_categories; // Catégories activés

    cookieCategories.forEach(category => {
      // Si la catégorie à des cookies et qu'elle est activé par le user
      if (category.cookie_table && acceptedCats.includes(category.toggle.value)) {
        // Retire le cookie de la liste de cookies à effacer
        category.cookie_table.forEach(cookie => {
          cookies = cookies.filter(name => !name.includes(cookie.COL1));
        })
      }
    });
  }

  // Loop sur chaque cookies du sites qui ne sont pas dans des catégories de Byscuit activés
  cookies.forEach(cookie => {
    let url = window.location.hostname.split('.'); // Split le domaine dans un array à chaque .

    // Loop pour essayer tous les domaines que les cookies peuvent utiliser. Ex: dev.villemontlaurier.qc.ca -> villemontlaurier.qc.ca -> qc.ca -> ca
    while (url.length > 0) {
      // On redéfinit la date d'expiration du cookie au 1 Janvier 1970 pour qu'il se fasse supprimer
      let expiredCookie = `${encodeURIComponent(cookie.split(';')[0].split('=')[0])}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=${url.join('.')} ;path=/`;
      document.cookie = expiredCookie;

      let path = location.pathname.split('/'); // On split le path actuel dans un array à chaque /

      // Loop pour essayer tous les path que les cookies peuvent utiliser
      while (path.length > 0) {
        document.cookie = expiredCookie + path.join('/'); // Rajoute le path au cookie pour être sûr de supprimer tous les cookies
        path.pop(); // Pop le path pour essayer la combinaison suivante
      };

      url.shift(); // Shift le domaine pour essayer la combinaison suivante
    }
  });

  if (reload) {
    // Reload la page pour que Byscuit supprime les cookies externes de catégories désactivés
    location.reload();
  }
}

// Initialisation sur toutes les pages
export class View {
  static init() {
    // Quand Byscuit est init on ajoute les eventlistener pour updater les cookies
    waitForElementToDisplay('#s-rall-bn',function(){
      updateCookies(false);

      // Boutons accepter, refuser et sauvegarder dans la sidebar
      document.querySelector('#s-all-bn').addEventListener('click', updateCookies);
      document.querySelector('#s-rall-bn').addEventListener('click', updateCookies);
      document.querySelector('#s-sv-bn').addEventListener('click', updateCookies)

      // Si le cookie cc_cookie_byscuit existe le bouton #c-t-bn et #c-p-bn sont pas init
      const btnDeclinePopup = document.querySelector('#c-t-bn');
      if (btnDeclinePopup) {
        // Boutons accepter et refuser dans le popup
        document.querySelector('#c-p-bn').addEventListener('click', updateCookies);
        btnDeclinePopup.addEventListener('click', updateCookies);
      }
    }, 1000, 10000);

    PARALLAX.init({ customScroll: true });
    SCROLLFIRE.init({ customScroll: true, mobile: true });
    svg4everybody();
    removeHoverOnMobile();
    consoleMessage();
    goToBackend();
    cssVarsIE();
    fixObjectFitIE();
    fixedArrow();
    overlayMenu();
    hundredVH();
    backToTopAndLinks();
  }

  static leave() {
    setTimeout(() => {
      PARALLAX.destroy();
      SCROLLFIRE.destroy();
      SCROLLFIRE.destroy();
      OBSERVER.allOff();
      document.querySelector('html').dataset.theme = '';
    }, 400);
  }
}

// Classe initialisant les scripts présents dans la page d'accueil
export class Home extends View {
  static initHome() {
    this.init();
    clickToScrollToBlock({ selector: '[data-js="scrolldown"]', scrollTo: '[data-js="scrolldown-destination"]' });
  }

  static leaveHome() {
    this.leave();
  }
}

// Classe initialisant les scripts présents dans la page approche
export class Approach extends View {
  static initApproach() {
    this.init();
    clickToScrollToBlock({ selector: '[data-js="scrolldown"]', scrollTo: '[data-js="scrolldown-destination"]' });
    slickCitation();
  }

  static leaveApproach() {
    this.leave();
    destroySlick('#slickCitation');
  }
}

// Classe initialisant les scripts présents dans la page d'expertise
export class Expertise extends View {
  static initExpertise() {
    this.init();
    clickToScrollToBlock({ selector: '[data-js="scrolldown"]', scrollTo: '[data-js="scrolldown-destination"]' });
  }

  static leaveExpertise() {
    this.leave();
  }
}

// Classe initialisant les scripts présents dans la page d'innovation municipale
export class Innovation extends View {
  static initInnovation() {
    this.init();
    dragAndSlide();
    drawers('.p_drawersList');
    slickEtude();
    clickToScrollToBlock({ selector: '[data-js="scrolldown-approche"]', scrollTo: '[data-js="scrolldown-destination-approche"]' });
    clickToScrollToBlock({ selector: '[data-js="scrolldown-expertise"]', scrollTo: '[data-js="scrolldown-destination-expertise"]' });
    clickToScrollToBlock({ selector: '[data-js="scrolldown-etudeDeCas"]', scrollTo: '[data-js="scrolldown-destination-etudeDeCas"]' });
    clickToScrollToBlock({ selector: '[data-js="scrolldown-demo"]', scrollTo: '[data-js="scrolldown-destination-demo"]' });
  }

  static leaveInnovation() {
    this.leave();
  }
}

// Classe initialisant les scripts présents dans la page des projets
export class Projects extends View {
  static initProjects() {
    this.init();
    dragAndSlide();
    projectsFilters();
  }

  static leaveProjects() {
    this.leave();
  }
}

// Classe initialisant les scripts présents dans la page d'un projet
export class Project extends View {
  static initProject() {
    this.init();
    imageStrip();
    slickProject();
    slickThreeImages();
  }

  static leaveProject() {
    this.leave();
  }
}

// Classe initialisant les scripts présents dans la page de contact
export class Contact extends View {
  static initContact() {
    this.init();
  }

  static leaveContact() {
    this.leave();
  }
}
