// ----------------------------- \\
// --- Transition avec barba --- \\
// ----------------------------- \\

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// C'est ici que les transition entre les page sont gérées, on y retrouve les différentes views spécifiques à
// chaque page. Tout commence avec la fonction doOnce.
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js';
import $ from 'jquery';
import setView from './views';
//-----------------------------------------------------------------------------------------------------------------

// Récupérer l'élément cliqué
window.transitionElement = '';

// Cette fonctions exécute la transition entre les pages
function setTransition() {
  const FadeTransition = Barba.BaseTransition.extend({
    start: function start() {
      Promise
        .all([this.newContainerLoading, this.fadeOut()])
        .then(this.fadeIn.bind(this));
    },
    fadeOut: () => $('html').addClass('transition').delay(500).promise(),
    fadeIn: function fadein() {
      if (!window.location.hash) $(document).scrollTop(0);
      $('html').addClass('end').removeClass('transition').delay(700)
        .queue(function animation(next) {
          $(this).removeClass('end');
          next();
        });
      this.done();
    },
  });

  Barba.Pjax.getTransition = () => FadeTransition;
}

// Cette fonction Gère la transition entre les page
export default function initPageTransition() {
  Barba.Pjax.cacheEnabled = false;
  Barba.Pjax.Dom.wrapperId = 'transition-wrapper';
  Barba.Pjax.Dom.containerClass = 'transition-container';
  Barba.Pjax.ignoreClassLink = 'no-transition';
  Barba.Dispatcher.on('initStateChange', () => {
    if (typeof gtag === 'function') {
      gtag('config', 'UA-22407563-2', {
        page_path: location.pathname,
      }); // Google tag
    }
    if (typeof fbq === 'function') {
      fbq('track', '1690967034608033', 'PageView'); // Pixel Facebook
    }
  });
  setTransition();
  setView();
  Barba.Pjax.start();
}
