// --------------------------- \\
// --- Fonctions générales --- \\
// --------------------------- \\

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier on retrouve les fonctions générales qu'on ne peut pas vraiment catégoriser
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery';
import cssVars from 'css-vars-ponyfill';
import anime from 'animejs/lib/anime.es';
import { isIE, getElementOffset } from './helper';
import { OBSERVER, SCROLLER, SCROLLFIRE } from '../main';
//-----------------------------------------------------------------------------------------------------------------

// Fonction permettant d'afficher un message dans la console
export function consoleMessage() {
  // eslint-disable-next-line no-console
  console.log(`%c
    ###########    ##                            ###
    ###      ###   ##                            ###
    ###      ###   ##    ########   ##  ######   ###    ###   #######
    ##########     ##   ##     ###  ####    ###  ###  ###   ###     ###
    ###      ###   ##       ######  ###     ###  ######     ##       ###
    ###       ###  ##   ####   ###  ###     ###  #### ###   ##       ###
    ###       ###  ##  ###     ###  ###     ###  ###   ###  ###      ##
    ############   ##   ##########  ###     ###  ###    ###  #########

    Tu manges du code pour déjeuner et les projets stimulants, ça te parle? Intéressé à joindre notre équipe 5✰?
    C'est maintenant que ça commence! Parle-nous de toi et dis-nous pourquoi tu es le développeur qu'il nous faut > info@blanko.ca`, 'font-family:monospace');
}

// Fonction permettant d'aller dans le backend avec un raccourcis clavier
export function goToBackend() {
  function keydown(e) {
    const keycode = (e.keyCode ? e.keyCode : e.which);
    if ((e.ctrlKey && keycode === 13) || (e.metaKey && keycode === 13)) {
      location = '/administration';
      return false;
    }
    return true;
  }

  OBSERVER.add('goToBackend', 'keydown', keydown, 'body');
  OBSERVER.on('goToBackend');
}

// Fonction exécutant l'animation du scroll vers son bloc
export function scrollToBlock(object = {}) {
  const options = {};
  options.duration = object.duration === undefined ? 800 : object.duration;
  options.scrollTo = object.scrollTo === undefined ? 'html, body' : object.scrollTo;
  options.easing = object.easing === undefined ? 'easeInOutQuart' : object.easing;
  options.offset = object.offset === undefined ? 0 : object.offset;

  const wd = window.document;
  const scrollbar = wd.scrollingElement || wd.body || wd.documentElement;
  const element = typeof unknownElement === 'string' ? document.querySelector(options.scrollTo) : options.scrollTo;

  const animation = anime
    .timeline({
      targets: scrollbar,
      duration: options.duration,
      easing: options.easing,
    })
    .add({ scrollTop: getElementOffset({ element }).top + options.offset });

  return animation.finished;
}

// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export function clickToScrollToBlock(object) {
  const options = {};
  options.selector = object.selector;
  options.duration = object.duration === undefined ? 800 : object.duration;
  options.scrollTo = object.scrollTo === undefined ? 'html, body' : object.scrollTo;
  options.easing = object.easing === undefined ? 'easeInOutQuart' : object.easing;
  options.offset = object.offset === undefined ? 0 : object.offset;

  const onClick = () => scrollToBlock({
    scrollTo: options.scrollTo,
    duration: options.duration,
    easing: options.easing,
    offset: options.offset,
  });

  OBSERVER.add('scrollToBlock', 'click', onClick, options.selector);
  OBSERVER.on('scrollToBlock');
}

// Fonction gérant la flèche en position fixed
export function fixedArrow() {
  const arrow = document.getElementById('fixedArrow');

  function onScroll() {
    if (SCROLLER.getPageOffset() > 100) {
      arrow.classList.add('backToTop');
    } else {
      arrow.classList.remove('backToTop');
    }
  }

  OBSERVER.add('fixedArrow', SCROLLER.getScrollEvent(), onScroll);
  OBSERVER.on('fixedArrow');
}

// ajoute ou enlève les bandes blanches de chaque côté de la bannière d'un projet
export function imageStrip() {
  let windowWidth;
  let stripWidth;
  let calcul;
  let result;
  const doc = document;
  const leftStrip = doc.querySelector('.section_header .left');
  const rightStrip = doc.querySelector('.section_header .right');
  let pageOffset = SCROLLER.getPageOffset();

  function getStripWidth() {
    windowWidth = window.innerWidth;

    if (windowWidth <= 600) stripWidth = 20;
    else if (windowWidth <= 768) stripWidth = 30;
    else if (windowWidth <= 1024) stripWidth = 50;
    else if (windowWidth <= 1200) stripWidth = 90;
    else if (windowWidth <= 1740) stripWidth = 120;
    else stripWidth = (windowWidth - 1500) / 2;
  }

  getStripWidth();

  function onScroll() {
    pageOffset = SCROLLER.getPageOffset();

    calcul = (stripWidth - (pageOffset / 4));
    if (calcul <= 0) {
      result = 0;
    } else if (calcul >= stripWidth) {
      result = stripWidth;
    } else {
      result = calcul;
    }

    leftStrip.style.width = `${result}px`;
    rightStrip.style.width = `${result}px`;
  }

  OBSERVER.add('imageStrip', SCROLLER.getScrollEvent(), onScroll);
  OBSERVER.add('imageStrip', 'resize', getStripWidth);
  OBSERVER.on('imageStrip');
}

// Permet la compatibilité des variables css avec IE
export function cssVarsIE(vars) {
  if (isIE()) {
    cssVars({
      onlyLegacy: false,
      preserveStatic: false,
      preserveVars: true,
      updateURLs: false,
      variables: vars,
    });
  }
}

// Fonction initialisant les tiroirs
export function drawers(accordions) {
  $(`${accordions} > li .clickable`).on('click', function clk() {
    const ouvert = $(this).parent().hasClass('open');
    $(`${accordions} li.open .drawer`).animate({ height: 'hide' }, { duration: 600, specialEasing: { height: 'easeInOutCubic' } });
    $(`${accordions} li.open`).removeClass('open');
    if (!ouvert) {
      const chosen = this;
      setTimeout(() => { $(chosen).parent().addClass('open'); }, 0);
      $('.drawer', $(this).parent()).stop(true, false).animate(
        { height: 'show' },
        {
          duration: 600,
          specialEasing: { height: 'easeInOutCubic' },
          complete: () => {
            $('html, body').animate({ scrollTop: $(chosen).offset().top - $('header').height() }, 600, 'easeInOutCubic');
          },
        },
      );
    }
    return false;
  });
}

// Permet de slider les catégories lorsqu'ils sont à l'horizontale
export function dragAndSlide() {
  const slider = document.querySelector('.dragAndSlide');
  let i;
  let startX;
  let timeout;
  let scrollLeft;
  let isDown = false;
  let preventDefault = false;

  const links = document.querySelectorAll('dragAndSlide a');
  const linksLength = links.length;

  for (i = 0; i < linksLength; i += 1) {
    links[i].ondragstart = () => false;
  }

  function mouseDown(e) {
    isDown = true;
    slider.classList.add('active');
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
    timeout = setTimeout(() => {
      preventDefault = true;
    }, 300);
  }

  function mouseleave() {
    isDown = false;
    slider.classList.remove('active');
  }

  function mouseup() {
    clearTimeout(timeout);
    isDown = false;
    slider.classList.remove('active');
  }

  function mousemove(e) {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 2;
    slider.scrollLeft = scrollLeft - walk;
  }

  function click(e) {
    if (preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
    preventDefault = false;
  }

  OBSERVER.add('dragAndSlide', 'mousedown', mouseDown, '.dragAndSlide');
  OBSERVER.add('dragAndSlide', 'mouseleave', mouseleave, '.dragAndSlide');
  OBSERVER.add('dragAndSlide', 'mouseup', mouseup, '.dragAndSlide');
  OBSERVER.add('dragAndSlide', 'mousemove', mousemove, '.dragAndSlide');
  OBSERVER.add('dragAndSlide', 'click', click, '.dragAndSlide a');
  OBSERVER.on('dragAndSlide');
}

// Permet de filtrer les projets
export function projectsFilters() {
  let i;
  let timeout;
  const doc = document;
  const projectsContainer = doc.getElementById('projectsContainer');
  const categoriesBtns = doc.querySelectorAll('.categoriesBtns a');
  const categoriesBtnsLength = categoriesBtns.length;
  const projects = Object.assign([], projectsContainer.children);
  const projectsLength = projects.length;

  SCROLLFIRE.destroy(); // Prevent scrollfire
  projectsContainer.innerHTML = ''; // Vide les projets

  // Affiche les projets de la catégorie sélectionnée
  function setFilters(filter, hasFadeOut = false) {
    const htmlProjectsLength = projectsContainer.children.length;

    // Faire apparaître les nouveaux projets
    function fadeIn() {
      for (i = 0; i < projectsLength; i += 1) {
        if (projects[i].dataset.project === filter || filter === 'all') {
          projects[i].dataset.scrollfire = '';
          projectsContainer.innerHTML += projects[i].outerHTML;
        }
      }

      projectsContainer.children[0].dataset.now = '';
      projectsContainer.children[1].dataset.now = '';
      if (!hasFadeOut) {
        projectsContainer.children[0].dataset.delay4 = '';
        projectsContainer.children[1].dataset.delay5 = '';
      } else {
        projectsContainer.children[1].dataset.delay1 = '';
      }

      SCROLLFIRE.init();
    }

    // Faire disparaître les anciens projets
    function fadeOut() {
      clearTimeout(timeout);
      SCROLLFIRE.destroy(); // Prevent scrollfire
      delete projectsContainer.children[1].dataset.delay1;
      delete projectsContainer.children[0].dataset.delay4;
      delete projectsContainer.children[1].dataset.delay5;

      for (i = 0; i < htmlProjectsLength; i += 1) {
        projectsContainer.children[i].dataset.scrollfire = '';
      }

      timeout = setTimeout(() => {
        projectsContainer.innerHTML = ''; // Vide les projets
        fadeIn();
      }, 800);
    }

    if (hasFadeOut) fadeOut();
    if (!hasFadeOut) fadeIn();
  }

  // Défini le bon url associé au bon filtre
  function setUrl(e) {
    const pathArray = window.location.pathname.split('/').filter((ev) => ev !== '');
    const filter = e.currentTarget.dataset.category;

    for (i = 0; i < categoriesBtnsLength; i += 1) {
      categoriesBtns[i].classList.remove('active');
    }

    if (e.currentTarget.dataset.category === 'toutVoir') {
      window.history.pushState('', 'toutVoir', `/${pathArray[0]}/${pathArray[1]}`);
      categoriesBtns[0].classList.add('active');
      setFilters('all', true);
    } else {
      window.history.pushState('', 'toutVoir', `/${pathArray[0]}/${pathArray[1]}/${filter}`);
      for (i = 0; i < categoriesBtnsLength; i += 1) {
        if (categoriesBtns[i].dataset.category === filter) {
          categoriesBtns[i].classList.add('active');
        }
      }
      setFilters(filter, true);
    }
  }

  OBSERVER.add('setUrl', 'click', setUrl, '.categoriesBtns a');
  OBSERVER.on('setUrl');

  // Va chercher l'url pour définir le filtre à appliquer
  function getUrl() {
    const pathArray = window.location.pathname.split('/').filter((e) => e !== '');
    const filter = pathArray[pathArray.length - 1];

    if (filter === 'projets') {
      categoriesBtns[0].classList.add('active');
      setFilters('all');
    } else {
      for (i = 0; i < categoriesBtnsLength; i += 1) {
        if (categoriesBtns[i].dataset.category === filter) {
          categoriesBtns[i].classList.add('active');
        }
      }
      setFilters(filter);
    }
  }

  getUrl();
}
