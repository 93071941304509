// ------------------------------- \\
// --- Importation des scripts --- \\
// ------------------------------- \\

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main.js importe tous les fichiers qu'on a de besoin, ça commence par les node-modules, puis les fichiers
// propres au projet.
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import $ from 'jquery';
import 'jquery.easing';
import 'jquery-validation';
import 'slick-carousel';
import 'jquery-mask-plugin';
import 'css-vars-ponyfill';

// Importation des scripts __________
import './global';

// Importation pour les scripts un peu plus bas __________
import initPageTransition from './transition';
import {
  Scrollfire,
} from './classes/Scrollfire';
import {
  Observer,
} from './classes/Observer';
import {
  Scroller,
} from './classes/Scroller';
import {
  Parallax,
} from './classes/Parallax';

window.jQuery = $;

// Démarrage des scripts __________
export const OBSERVER = new Observer();
export const SCROLLER = new Scroller();
export const PARALLAX = new Parallax();
export const SCROLLFIRE = new Scrollfire();

window.addEventListener('DOMContentLoaded', () => {
  initPageTransition();
});
