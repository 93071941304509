//--------------------------\\
//---- ClickEvent Class ----\\
//--------------------------\\

export class ClickEvent {

  constructor(instance) {
    if (instance !== undefined)
      this.instance = instance

    this.init()
  }


  // ----------------------------------------------------------------------------------------------------------------------------
  // Initialization of all click events
  // ----------------------------------------------------------------------------------------------------------------------------
  init() {
    let i
    let doc = document

    // The open click event
    if (this.instance.overlay.click.buttons.open !== undefined) {
      this.openButtons = doc.querySelectorAll(this.instance.overlay.click.buttons.open)
      let openButtonsLength = this.openButtons.length
      for (i = 0; i < openButtonsLength; i++) {
        this.boundOpen = e => this.instance.open(e)
        this.openButtons[i].addEventListener('click', this.boundOpen, false)
      }
    }

    // The close click event
    if (this.instance.overlay.click.buttons.close !== undefined) {
      this.closeButtons = doc.querySelectorAll(this.instance.overlay.click.buttons.close)
      let closeButtonsLength = this.closeButtons.length
      for (i = 0; i < closeButtonsLength; i++) {
        this.boundClose = e => this.instance.close(e)
        this.closeButtons[i].addEventListener('click', this.boundClose, false)
      }
    }

    // The toggle click event
    if (this.instance.overlay.click.buttons.toggle !== undefined) {
      this.toogleButtons = doc.querySelectorAll(this.instance.overlay.click.buttons.toggle)
      let toogleButtonsLength = this.toogleButtons.length
      for (i = 0; i < toogleButtonsLength; i++) {
        this.boundToogle = e => this.instance.toggle(e)
        this.toogleButtons[i].addEventListener('click', this.boundToogle, false)
      }
    }

    // The trigger click event
    if (this.instance.overlay.click.buttons.trigger !== undefined) {
      this.triggerButtons = doc.querySelectorAll(this.instance.overlay.click.buttons.trigger)
      let triggerButtonsLength = this.triggerButtons.length
      for (i = 0; i < triggerButtonsLength; i++) {
        this.boundTrigger = e => this.instance.trigger(e)
        this.triggerButtons[i].addEventListener('click', this.boundTrigger, false)
      }
    }

    // The switch click event
    if (this.instance.overlay.click.buttons.switch !== undefined) {
      this.switchButtons = doc.querySelectorAll(this.instance.overlay.click.buttons.switch)
      let switchButtonsLength = this.switchButtons.length
      for (i = 0; i < switchButtonsLength; i++) {
        this.boundSwitch = e => this.instance.switch(e)
        this.switchButtons[i].addEventListener('click', this.boundSwitch, false)
      }
    }
  }



  // ----------------------------------------------------------------------------------------------------------------------------
  // Destroying all click events
  // ----------------------------------------------------------------------------------------------------------------------------
  destroy() {
    let i

    // The open click event
    if (this.instance.overlay.click.buttons.open !== undefined) {
      let openButtonsLength = this.openButtons.length
      for (i = 0; i < openButtonsLength; i++)
        this.openButtons[i].removeEventListener('click', this.boundOpen, false)
    }

    // The close click event
    if (this.instance.overlay.click.buttons.close !== undefined) {
      let closeButtonsLength = this.closeButtons.length
      for (i = 0; i < closeButtonsLength; i++)
        this.closeButtons[i].removeEventListener('click', this.boundClose, false)
    }

    // The toggle click event
    if (this.instance.overlay.click.buttons.toggle !== undefined) {
      let toogleButtonsLength = this.toogleButtons.length
      for (i = 0; i < toogleButtonsLength; i++)
        this.toogleButtons[i].removeEventListener('click', this.boundToogle, false)
    }

    // The trigger click event
    if (this.instance.overlay.click.buttons.trigger !== undefined) {
      let triggerButtonsLength = this.triggerButtons.length
      for (i = 0; i < triggerButtonsLength; i++)
        this.triggerButtons[i].removeEventListener('click', this.boundTrigger, false)
    }

    // The switch click event
    if (this.instance.overlay.click.buttons.switch !== undefined) {
      let switchButtonsLength = this.switchButtons.length
      for (i = 0; i < switchButtonsLength; i++)
        this.switchButtons[i].removeEventListener('click', this.switchToogle, false)
    }
  }
}
