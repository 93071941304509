// |--------------------------------------------------------------------------
// | Back to top and links
// |--------------------------------------------------------------------------
// |
// | Fonction contenant le tout le nécéssaire pour faire fonctionner un bloc
// | sticky du back to top.
// |
// | IMPORTS _____
// | -> Observer
// | -> clickToScrollToBlock
// |
// | OPTIONS _____
// | container => Le container sticky.
// | backToTopSelector => Le sélecteur de l'élément pour retourner en haut.
// | distance => La distance en pixels avant que le contenu invisible soit
// |             visible.
// | showClass => La classe ajoutée pour rendre visible le contenu caché.
// |--------------------------------------------------------------------------

// Importation ---------------------------------------------------------------
import { OBSERVER } from '../main';
import { clickToScrollToBlock } from './functions';
// ---------------------------------------------------------------------------

export default function backToTopAndLinks(object = {}) {
  // Déclaration des propriétés par défaut
  const options = {};
  options.container = object.container === undefined ? '[data-js="back-to-top-and-links"]' : object.container;
  options.backToTopSelector = object.backToTopSelector === undefined ? '[data-js="back-to-top"]' : object.backToTopSelector;
  options.distance = object.distance === undefined ? 500 : object.distance;
  options.showClass = object.showClass === undefined ? 'displayed' : object.showClass;

  // Déclarations des variables
  const container = document.querySelector(options.container);

  // Déclarations des fonctions
  const manageBackToTop = () => {
    if (document.documentElement.scrollTop >= options.distance) {
      container.classList.add(options.showClass);
    } else {
      container.classList.remove(options.showClass);
    }
  };

  // Si le container existe
  if (typeof container !== 'undefined' && container !== null) {
    // Permet de retourner en haut de la page
    clickToScrollToBlock({ selector: options.backToTopSelector });

    // Appel de la fonction au moins une fois
    manageBackToTop();

    // Ajout de l événement pour masquer ou afficher le contenu
    OBSERVER.add('manageBackToTop', 'scroll', manageBackToTop);
    OBSERVER.on('manageBackToTop');
  }
}
