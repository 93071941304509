// ----------------- \\
// --- Les views --- \\
// ----------------- \\

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ce fichier contient une seule fonction qui celle-ci contient toutes les views du site. Une views équivaux à
// une page web (homeView = la page d'accueil). On appelle seulement les classes dans global dans les vues, on
// n'appelle pas d'autres fonctions ou déclarer quoi que ce soit dans les vues afin de garder le tout clair.
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js';
// import $ from 'jquery'

import {
  View, Home, Approach, Expertise, Innovation, Projects, Project, Contact,
} from './global';
//-----------------------------------------------------------------------------------------------------------------

export default function setView() {
  // Page standart __________
  Barba.BaseView.extend({
    namespace: 'sdtView',
    onEnterCompleted: () => { View.init(); },
    onLeave: () => { View.leave(); },
  }).init();

  // Page d'accueil __________
  Barba.BaseView.extend({
    namespace: 'homeView',
    onEnterCompleted: () => { Home.initHome(); },
    onLeave: () => { Home.leaveHome(); },
  }).init();

  // Page approche __________
  Barba.BaseView.extend({
    namespace: 'approachView',
    onEnterCompleted: () => { Approach.initApproach(); },
    onLeave: () => { Approach.leaveApproach(); },
  }).init();

  // Page approche __________
  Barba.BaseView.extend({
    namespace: 'expertiseView',
    onEnterCompleted: () => { Expertise.initExpertise(); },
    onLeave: () => { Expertise.leaveExpertise(); },
  }).init();

  // Page innovation __________
  Barba.BaseView.extend({
    namespace: 'innovationView',
    onEnterCompleted: () => { Innovation.initInnovation(); },
    onLeave: () => { Innovation.leaveInnovation(); },
  }).init();

  // Page des projets __________
  Barba.BaseView.extend({
    namespace: 'projectsView',
    onEnterCompleted: () => { Projects.initProjects(); },
    onLeave: () => { Projects.leaveProjects(); },
  }).init();

  // Page d'un projet __________
  Barba.BaseView.extend({
    namespace: 'projectView',
    onEnterCompleted: () => { Project.initProject(); },
    onLeave: () => { Project.leaveProject(); },
  }).init();

  // Page de contact __________
  Barba.BaseView.extend({
    namespace: 'contactView',
    onEnterCompleted: () => { Contact.initContact(); },
    onLeave: () => { Contact.leaveContact(); },
  }).init();
}
