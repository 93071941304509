// ----------------------- \\
// --- Carousel Slicks --- \\
// ----------------------- \\

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Toutes les fonctions utilisant les carousel de slick se retrouve ici. Ces fonction ont besoin du
// node-module slick carousel pour fonctionner (et le css qui vient avec).
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery';
//-----------------------------------------------------------------------------------------------------------------

// Création du slick de la bannière
export function slickCitation() {
  $('#slickCitation').slick({
    arrows: false,
    dots: true,
    appendDots: document.querySelector('.slickNav'),
    infinite: true,
    autoplay: true,
    draggable: true,
    speed: 500,
    autoplaySpeed: 10000,
    fade: true,
  });
}

// Création du slick de la bannière
export function slickQuotes() {
  $('#slickQuotes').slick({
    asNavFor: '#slickAuthors',
    arrows: false,
    dots: true,
    appendDots: document.querySelector('.slickNav'),
    infinite: true,
    autoplay: true,
    draggable: true,
    speed: 500,
    autoplaySpeed: 10000,
    fade: true,
  });

  $('#slickAuthors').slick({
    asNavFor: '#slickQuotes',
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    draggable: true,
    speed: 500,
    autoplaySpeed: 10000,
    fade: true,
  });
}

// Création du slick de la bannière
export function slickEtude() {
  $('#slickEtude').slick({
    arrows: false,
    dots: false,
    infinite: false,
    centerMode: true,
    autoplay: true,
    draggable: true,
    variableWidth: true,
    speed: 500,
    autoplaySpeed: 10000,
  });

  const total = $('.slickEtude .slick-slide:not(.slick-cloned)').length;
  $('#slickEtudeNav').on('click', () => {
    if ($('.slick-current').attr('data-slick-index') === total - 1) {
      $('#slickEtude').slick('slickGoTo', 0);
    } else {
      $('#slickEtude').slick('next');
    }
  });
}

// Création du slick d'un projet
export function slickProject() {
  $('#slickImage').slick({
    arrows: false,
    dots: false,
    draggable: true,
    speed: 500,
    infinite: false,
  });

  const total = $('.section_imageSlider .slick-slide:not(.slick-cloned)').length;
  $('.section_imageSlider .slickNav .total').html(total);

  $('#slickImage').on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    const slidePosition = nextSlide + 1;
    $('.section_imageSlider .slickNav .current').html(slidePosition);
  });
}

// Création du slick des trois images d'un projet
export function slickThreeImages() {
  function addSlick() {
    $('#slickThreeImages').slick({
      arrows: false,
      dots: false,
      infinite: true,
      autoplay: true,
      draggable: true,
      speed: 500,
      autoplaySpeed: 10000,
      slidesToShow: 1,
    });
  }

  if ($(window).width() <= 600) {
    addSlick();
  }

  $(window).resize(() => {
    if ($(window).width() <= 600 && !$('#slickThreeImages').hasClass('slick-initialized')) {
      addSlick();
    } else if ($(window).width() >= 600 && $('#slickThreeImages').hasClass('slick-initialized')) {
      $('#slickThreeImages').slick('unslick');
    }
  });
}

// Fonction permettant de détruire un carousel Slick
export function destroySlick(carousel, delai) {
  // Paramètres optionnels
  const delay = (typeof delai === 'undefined') ? 1000 : delai;

  // Un délai pour que le slick ne se détruise pas avant que la transition de page ne soit apparu
  setTimeout(() => {
    $(carousel).slick('unslick');
  }, delay);
}
