// ---------------------------- \\
// ---- Popup et sidepanel ---- \\
// ---------------------------- \\

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ici on retrouve tout ce qui a attrait au popup et au sidepanel (popup de connexion, menu mobile)
// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import { Overlay } from '../classes/Overlay/Overlay';
//-----------------------------------------------------------------------------------------------------------------

// Fonction gérant le menu mobile
export function overlayMenu() {
  // eslint-disable-next-line no-unused-vars
  const OVERLAY = new Overlay({
    name: 'Menu',
    structure: {
      openingClass: 'openingMenu',
      closingClass: 'closingMenu',
    },
    click: {
      buttons: {
        close: '.overlay, .closeOverlay',
        toggle: '.btnMenu',
      },
    },
    options: {
      speed: 800,
    },
  });

  /* new Overlay({
    name: 'Menu',
    structure: {
      buttons: {
        toggle: '.btnMenu',
        close: '.overlay, .closeOverlay',
      }
    },
    event: {
      name: 'click',
      speed: 800
    },
  }) */
}
